import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Blocks from '../components/blocks';
import Header from '../components/header';
import Footer from '../components/footer';

import logo from '../images/hospitaalbroeders-logo-white.svg';
import anbi from '../images/logo-anbi-diap.png';
import cbf from '../images/logo-cbf-diap.png';

export const query = graphql`
  query pageDataQuery($id: String!) {
    site {
      siteMetadata {
        title
        description
        author
      }
    }

    page: contentfulCampaignPage(id: { eq: $id }) {
      id
      title
      slug
      blocks: contentBlocks {
        type: __typename
        ...BlockCopyFields
        ...BlockHeroFields
        ...BlockFeaturesFields
        ...BlockIntroFields
        ...BlockShareFields
        ...BlockDonateFields
        ...BlockDonateCtaFields
        ...BlockPetitionFields
        ...BlockMailingListFields
        ...BlockCarouselFields
        ...BlockFormFields
        ...BlockMapFields
      }
    }
  }
`;

const FooterLogo = styled.div`
  margin: 0;
  padding: 2rem;
  text-align: center;

  ${({ theme }) => theme.media.tablet`
    padding: 3rem 1rem;
    text-align: left;
  `}

  img {
    display: inline-block;
    height: 5rem;
  }
`;

const Acknowledgements = styled(FooterLogo)`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    margin: 1rem;
  }

  ${({ theme }) => theme.media.tablet`
    justify-content: flex-end;

    a {
      margin: 0;
      margin-left: 2rem;
    }
  `}

  

  img {
    height: 3.25rem;
  }
`;


const CampaignPageTemplate = ({ data }) => {
  const {
    site, page,
  } = data;
  const { title, slug, blocks } = page;

  const pageHeader = (
    <SEO title={title} slug={slug} />
  );

  const pageFooter = (
    <Footer>
      <FooterLogo>
        <img src={logo} alt={site.siteMetadata.title} />
      </FooterLogo>
      <Acknowledgements>
        <a href="https://anbi.nl">
          <img src={anbi} alt="Anbi" />
        </a>
        <a href="https://www.cbf.nl">
          <img src={cbf} alt="CBF" />
        </a>
      </Acknowledgements>
    </Footer>
  );

  return (
    <Layout
      header={pageHeader}
      footer={pageFooter}
    >
      <Blocks blocks={blocks || []} />
    </Layout>
  );
};

CampaignPageTemplate.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape.isRequired,
    page: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      blocks: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
      })),
    }),
    // navigation: PropTypes.shape.isRequired,
    // footer: PropTypes.shape({
    //   title: PropTypes.string.isRequired,
    //   blocks: PropTypes.arrayOf(PropTypes.shape({
    //     type: PropTypes.string.isRequired,
    //   })),
    // }),
  }).isRequired,
};

export default CampaignPageTemplate;
